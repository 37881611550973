'use client'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
    useEffect(() => {
        if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_APP_ENV === 'production') {
            posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
                api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
                person_profiles: 'always',
                capture_pageview: false // Disable automatic pageview capture, as we capture manually
            })
        }
    }, [])

    return (
        <PostHogProvider client={posthog}>
            {children}
        </PostHogProvider>
    )
}